import { images } from '.';

const works = [
  { title: 'Accelerometer', description: "Obtains the data from your accelerometer in a time 'x', with time intervals 'y' in milliseconds and send the information wherever you want (Email, WhatsApp, etc.).", projectLink: 'https://play.google.com/store/apps/details?id=com.grisu.accelerometer', codeLink: 'https://github.com/Youngermaster/Accelerometer', image: images.accelerometer, tags: ['Mobile App'] },
  { title: 'Hacker Rank', description: 'Hack The Box profile with a Hacker Rank.', projectLink: 'https://app.hackthebox.com/profile/643960', codeLink: 'https://app.hackthebox.com/profile/643960', image: images.hackTheBoxProfile, tags: ['Security'] },
  { title: 'HiWay', description: "I worked with some partners in an app where you can visualize in real time the different means of public transport, so you won't have to wait so long and you will optimize your time.", projectLink: 'https://play.google.com/store/apps/details?id=com.camilogalvis.hiway', codeLink: 'https://play.google.com/store/apps/details?id=com.camilogalvis.hiway', image: images.hiWay, tags: ['Mobile App', 'IOT'] },
  { title: 'Anthropometric Evaluator', description: 'This application allows you to evaluate your body to know what state you are in, given some measurements that you will have to give.', projectLink: 'https://play.google.com/store/apps/details?id=com.grisu.anthropometric_evaluator', codeLink: 'https://github.com/Youngermaster/Anthropometric-Evaluator', image: images.anthropometricEvaluator, tags: ['Mobile App'] },
  { title: 'Platzi Community Wrapper Eraser Extension', description: "The idea of this project is to remove the Community section on the Platzi's courses.", projectLink: 'https://chrome.google.com/webstore/detail/platzi-community-wrapper/dakgbbfpefoofghfbkopnbnpadeblbep', codeLink: 'https://github.com/Youngermaster/Platzi-community-wrapper-eraser-extension', image: images.platziWrapperEraserExtension, tags: ['Web App', 'Miscellaneous'] },
  { title: 'Tractor Agro Controller App', description: 'This app allow us to control a simulated IOT tractor through an app.', projectLink: 'https://github.com/Youngermaster/Agro-Controller-App', codeLink: 'https://github.com/Youngermaster/Agro-Controller-App', image: images.agroController, tags: ['IOT', 'Mobile App'] },
  { title: 'Pomodoro CLI', description: 'This is a pomodoro CLI to be more productive', projectLink: 'https://github.com/Youngermaster/Pomodoro-CLI', codeLink: 'https://github.com/Youngermaster/Pomodoro-CLI', image: images.pomodoroCli, tags: ['Miscellaneous'] },
  { title: 'Celestial Body Interaction', description: 'This project make a simulator of the interaction of N (a natural number given) bodies in a 2D space.', projectLink: 'https://github.com/Youngermaster/Celestial-Object-Interaction', codeLink: 'https://github.com/Youngermaster/Celestial-Object-Interaction', image: images.celestialBodyInteraction, tags: ['Miscellaneous'] },
  { title: 'YASIC', description: 'On this repository you will find a programming language similar to BASIC.', projectLink: 'https://github.com/Youngermaster/YASIC', codeLink: 'https://github.com/Youngermaster/YASIC', image: images.yasic, tags: ['Miscellaneous'] },
  { title: 'ST0256 Numerical Analysis', description: "The idea of this project is to test out if it is worth it to use C++ instead of Python for algorithms of some numeric methods, like finding roots of non-linear equations using Newton-Raphson's method.", projectLink: 'https://github.com/Youngermaster/ST0256-Numerical-Analysis', codeLink: 'https://github.com/Youngermaster/ST0256-Numerical-Analysis', image: images.numericalAnalysis, tags: ['Web App', 'Miscellaneous'] },
  { title: 'Unity 3D FPS', description: 'This is a First Person Shooter made with Unity 3D without any ending, just chill and explore this world, and you can download it here.', projectLink: 'https://github.com/Youngermaster/Unity-FPS', codeLink: 'https://github.com/Youngermaster/Unity-FPS', image: images.unityFPS, tags: ['Gaming'] },
];

export default works;
